import React from 'react';
import { Table } from 'react-bootstrap';
const config = require('../../config.json');

export default function OffcanvasTemplate(props) {
    const { selectedBook } = props;

    const handleImageError = (event) => {
        event.target.src = "images/cat1.png";
      };
    

  return (
    <div>
        <div className='text-primary container' >
        <div className="img-box" style={{textAlign:"center"}}>
                <img src={`${config.s3.bookPoster}${selectedBook.accessionNo}${".jpeg"}`} alt=""
                onError={handleImageError} style={{width:"6rem", height:"6rem"}} />
              </div>
              
            <Table striped bordered hover>
        <tr>
            <th>Book Name</th>
            <td><i className='fa fa-book'></i></td>
            <td>
        <p className="features-title">{selectedBook.bookTitle}</p>
        </td></tr>
        <tr>
            <th>Author Name</th>
            <td><i className="fa fa-user" aria-hidden="true"></i></td>
            <td>            <h6 className='text-start'> {selectedBook.bookAuthor}</h6>
        </td></tr>
        <tr>
            <th>Book Status</th>
            <td>{selectedBook.bookStatus==="Active"?<i className="fa fa-check" aria-hidden="true"></i>:selectedBook.bookStatus==="Issued"?
                    <i class="fa fa-hourglass-half" aria-hidden="true"></i>:null}</td>
            <td>{selectedBook.bookStatus}</td>
        </tr>
        <tr>
            <th>Call No</th>
            <td><i class="fa fa-object-group" aria-hidden="true"></i>
</td>
            <td>{selectedBook.bookClassificationNo}{" "}{selectedBook.authorMark}</td>
        </tr><tr>
            <th>Publisher Name </th>
            <td><i className="fa fa-print" aria-hidden="true"></i></td>
            <td>{selectedBook.bookPublisherName}</td>
        </tr><tr>
            <th>Publishing Year</th>
            <td><i class="fa fa-calendar" aria-hidden="true"></i></td>
            <td>{selectedBook.bookPublishYear}</td>
        </tr>
    </Table>            
    </div>
    </div>
  )
}
