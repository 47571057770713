import React, {useState, useContext, useEffect} from 'react';
import BooksForm from './BooksForm';
import { Button, Modal, Table, ButtonGroup, Dropdown } from 'react-bootstrap';
import { useSnackbar } from 'notistack';
import { ProductContext } from '../../ProductProvider';
import axios from 'axios';
import Preloader from '../../components/Preloader/Preloader';
import BooksPreview from './BooksPreview';
//import { books_category } from '../../ProData';
import {Helmet} from 'react-helmet-async';
import { fetchAuthSession } from 'aws-amplify/auth';

const config = require('../../config.json');

export default function AddBooks() {
    const [bookDetails, setBookDetails] = useState({autoGenerate:true, accessionNo: "", bookAuthor: "", bookCategory: "NA", bookClassificationNo: "", bookEdition: "", bookPages: "", bookPrice: "", bookPublishYear: "", bookPublisherName: "", bookScope: "Circulation", bookStatus: "Active", bookTitle: "", clientID: "", registeredOn: "", updatedBy: "", updatedOn: "",authorMark:"", bookCopies:1, bookPublisherLocation:"", bookSubTitle:"", bookKeywords:"", bookAbstract:"", bookDimension:"", isbn:"" });
    const [viewOnly, setViewOnly] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const { validateLogin, loggedUser, handleImageError, generateDate, generateAccessionNo } = useContext(ProductContext);
    const [loaded, setLoaded] = useState(true);
    const [searchResult, setSearchResult]=useState([]);
    const [searchValue, setSearchValue]= useState('');
    const [searchTopic, setSearchTopic]=useState('AccessionNo');
    const [dispMsg, setDispMsg] = useState('');
    const [show, setShow] = useState(false);
    const [formType, setFormType]=useState('View');
    const [modalTitle, setModalTitle] = useState('');
    const [imageFile, setImageFile] = useState('');
    const [profileImage, setProfileImage] = useState("images/cat1.png");
    const [newFileName, setNewFileName]=useState('');


    useEffect(()=>{
        if(!loggedUser.isAuthenticated){
          validateLogin();
        }else{
            handleReset();
        }
      },[loggedUser])

    const handleNewSubmit=async(event)=>{
        event.preventDefault();
        //console.log("book details", bookDetails);
        try{
            setLoaded(false);
            const validationResult=validateFields();
            //const validationResult="passed";
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            if(validationResult==="passed"){
                const {autoGenerate, accessionNo, bookAuthor, bookCategory, bookClassificationNo, bookEdition, bookPages, bookPrice, bookPublishYear, bookPublisherName, bookScope, bookStatus, bookTitle, authorMark, bookCopies, bookPublisherLocation, bookSubTitle, bookKeywords, bookAbstract, bookDimension, isbn } = bookDetails;
                const todayDate=await generateDate();
                //console.log("Logged User : ", loggedUser);
                const params={
                    clientID:loggedUser.website,
                    accessionNo:accessionNo,
                    autoGenerate:autoGenerate,
                    registeredOn:todayDate,
                    bookAuthor:bookAuthor,
                    bookCategory:bookCategory,
                    bookClassificationNo:bookClassificationNo,
                    bookEdition:bookEdition,
                    bookPages:bookPages,
                    bookPrice:bookPrice,
                    bookPublishYear:bookPublishYear,
                    bookPublisherName:bookPublisherName,
                    bookScope:bookScope,
                    bookStatus:bookStatus,
                    bookTitle:bookTitle,
                    updatedOn:todayDate,
                    updatedBy:loggedUser.nickname,
                    authorMark:authorMark,
                    bookAbstract:bookAbstract,
                    bookKeywords:bookKeywords,
                    bookCopies:bookCopies,
                    bookPublisherLocation:bookPublisherLocation,
                    bookSubTitle:bookSubTitle,
                    bookDimension:bookDimension,
                    isbn:isbn
                };
                //console.log("Params : ", params);
                await axios.post(`${config.api.invokeURL}/vjlibrary/catalogue`,params, {  
                    headers: {
                      Authorization: idToken,
                      'x-api-key':config.api.key
                    }
                  }).then(async(response) => {
                      //console.log("Response : ", response);
                      if(imageFile)
                        {
                         postOnS3(accessionNo);
                        }     
                      setFormType("View");
                      setDispMsg(response.data);
                      handleClose();
                      enqueueSnackbar(response.data,{variant:'success'});
                      //handleReset();
                    setLoaded(true)
                    window.scrollTo(0,0);
                }).catch((error)=>{
                console.log("Error : ", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
                setLoaded(true);
                window.scrollTo(0,0);
              })
            }
        }catch(error){
            console.log("Error : ", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        }
    }

    const handleEditSubmit=async(event)=>{
        event.preventDefault();
        //console.log("book details", bookDetails);
        try{
          setLoaded(false);
            const validationResult=validateFields();
            //const validationResult="passed";
            if(validationResult==="passed"){
                const {clientID, accessionNo, bookAuthor, registeredOn, bookCategory, bookClassificationNo, bookEdition, bookPages, bookPrice, bookPublishYear, bookPublisherName, bookScope, bookStatus, bookTitle, authorMark, bookCopies, bookPublisherLocation, bookSubTitle, bookKeywords, bookAbstract, bookDimension, isbn } = bookDetails;
                const todayDate=await generateDate();
                const { idToken } = (await fetchAuthSession()).tokens ?? {};
                //const taccessionNo=await generateAccessionNo();
                //console.log("Logged User : ", loggedUser);
                var tisbn=isbn;
                if(isbn===undefined){
                  tisbn="NA";
                }
                const params={
                    clientID:clientID,
                    accessionNo:accessionNo,
                    registeredOn:registeredOn,
                    bookAuthor:bookAuthor,
                    bookCategory:bookCategory,
                    bookClassificationNo:bookClassificationNo,
                    bookEdition:bookEdition,
                    bookPages:bookPages,
                    bookPrice:bookPrice,
                    bookPublishYear:bookPublishYear,
                    bookPublisherName:bookPublisherName,
                    bookScope:bookScope,
                    bookStatus:bookStatus,
                    bookTitle:bookTitle,
                    updatedOn:todayDate,
                    updatedBy:loggedUser.nickname,
                    authorMark:authorMark,
                    bookAbstract:bookAbstract,
                    bookKeywords:bookKeywords,
                    bookCopies:bookCopies,
                    bookPublisherLocation:bookPublisherLocation,
                    bookSubTitle:bookSubTitle,
                    bookDimension:bookDimension,
                    isbn:tisbn
                };
                //console.log("Params : ", params);
                await axios.patch(`${config.api.invokeURL}/vjlibrary/catalogue`,params, {  
                    headers: {
                      Authorization: idToken,
                      'x-api-key':config.api.key
                    }
                  }).then(async(response) => {
                   //console.log("Response : ", response);
                   if(imageFile)
                   {
                    postOnS3(accessionNo);
                   }
                      enqueueSnackbar("Book Updated Successfully - " + accessionNo,{variant:'success'});
                      handleReset();
                      handleClose();
                    setLoaded(true)
                }).catch((error)=>{
                  console.log("Error : ", error);
                enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
                setLoaded(true);
                window.scrollTo(0,0);
              })
            }
        }catch(error){
            enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        }
    }

    const postOnS3 = async(taccessionNo)=>{
      //event.preventDefault();
      try{ 
          setLoaded(false);
          const { idToken } = (await fetchAuthSession()).tokens ?? {};
          const fileName=taccessionNo;
          const tnewFileName=fileName+".jpeg";
          const keyName='library/poster/';
  
          const bucketName=config.s3.bucketName;
  
        var file = imageFile;
        var blob = file.slice(0, file.size, 'image/jpeg'); 
        const newFile = new File([blob], tnewFileName, {type: 'image/jpeg'});
        const fileType="image/jpeg";
        await axios.get(`${config.api.invokeURL}/vjlibrary/catalogue/poster?fileName=${tnewFileName}&bucketName=${bucketName}&fileType=${fileType}&keyName=${keyName}`,{
             headers:{
                Authorization: idToken,
                'x-api-key': config.api.key
             }
         }).then(async(response)=>{
             //console.log("Response : ", response);
             await fetch(response.data.uploadURL, { method: 'PUT',
             headers:{
                 "Content-Type":"image/jpeg"
               },body: newFile})
               .then(async(res)=>{
               //console.log("Res : ", res);
               if(res.status===200){
                  //fetchGalleryImages();
                   setProfileImage(newFileName);
                   var tprofile = document.getElementById("profile");
                   tprofile.src="https://s3.ap-south-1.amazonaws.com/idntica.com/avatar/profileImage/defaultAvatar.jpeg";
                   //toggle();
               }
              }).catch((error)=>{
                  //console.log("Error :", error);
                  enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
                  setLoaded(true);
                  window.scrollTo(0,0);   
             })
          }).catch((error)=>{
              //console.log("Error :", error);
              enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
              setLoaded(true);
              window.scrollTo(0,0);   
         })
      
      }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'});
        setLoaded(true);
        window.scrollTo(0,0);   
      }
      setLoaded(true);
      }

    const handlePreviewView=(event)=>{
      event.preventDefault();
      const validationResult=validateFields();
      if(validationResult==="passed"){
      setFormType("Preview");
      setModalTitle('Add a Book');     
      setViewOnly(true);
      }
    }

    const validateFields=()=>{
        var returnValue="passed";
        const {autoGenerate, accessionNo, bookAuthor, bookCategory, bookClassificationNo, authorMark, bookCopies, bookPublisherLocation, bookSubTitle, bookPages, bookPrice, bookPublishYear, bookPublisherName, bookScope, bookStatus, bookTitle, bookKeywords } = bookDetails;
        if(!autoGenerate & accessionNo===""){
          enqueueSnackbar("Accession No is mandatory.",{variant : 'info'});
          returnValue="failed";
        }else if(!autoGenerate & bookCopies > 1){
            enqueueSnackbar("Book Copies cannot be more than 1 for Input Accession No",{variant : 'info'});
            returnValue="failed";
        }else if(bookAuthor===""){
          enqueueSnackbar("Author is mandatory.",{variant : 'info'});
          returnValue="failed";
        }else if(bookCategory===""){
          enqueueSnackbar("Book Category is mandatory.",{variant : 'info'});
          returnValue="failed";
        }else if(bookClassificationNo===""){
          enqueueSnackbar("Book Classification Number is mandatory.",{variant : 'info'});
          returnValue="failed";
        }else if(authorMark===""){
          enqueueSnackbar("Author Mark is mandatory!.",{variant : 'info'});
          returnValue="failed";
        }else if(bookPages===""){
          enqueueSnackbar("Book Pages is mandatory!",{variant : 'info'});
          returnValue="failed";
        }else if(bookPrice===""){
            enqueueSnackbar("Book Price is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookPublisherName===""){
            enqueueSnackbar("Book Publisher is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookPublishYear===""){
            enqueueSnackbar("Book Publish Year is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookTitle===""){
            enqueueSnackbar("Book Title is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookStatus===""){
            enqueueSnackbar("Book Status is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookScope===""){
            enqueueSnackbar("Book Scope is mandatory!",{variant : 'info'});
            returnValue="failed";
        }else if(bookCopies===""){
          enqueueSnackbar("Book Copies is mandatory!",{variant : 'info'});
          returnValue="failed";
      }else if(bookPublisherLocation===""){
        enqueueSnackbar("Book Publisher Location is mandatory!",{variant : 'info'});
        returnValue="failed";
    }else if(bookSubTitle===""){
      enqueueSnackbar("Book Sub Title is mandatory!",{variant : 'info'});
      returnValue="failed";
  }else if(bookCopies>10){
    enqueueSnackbar("Can add only maximum 10 copies at a time!",{variant : 'info'});
    returnValue="failed";
}else if(bookKeywords===""){
  enqueueSnackbar("Can add only maximum 10 copies at a time!",{variant : 'info'});
  returnValue="failed";
}else {
      returnValue="passed";
      }
    return returnValue;
    }
  
    const handleReset=async(event)=>{
        //event.preventDefault();
        const taccessionNo=await generateAccessionNo();
        setBookDetails({...bookDetails, autoGenerate:true, accessionNo: taccessionNo, bookAuthor: "", bookCategory: "NA", bookClassificationNo: "", bookEdition: "", bookPages: "", bookPrice: "", bookPublishYear: "", bookPublisherName: "", bookScope: "Circulation", bookStatus: "Active", bookTitle: "", clientID: "", registeredOn: "", updatedBy: "", updatedOn: "",authorMark:"", bookCopies:1, bookPublisherLocation:"", bookSubTitle:"", bookKeywords:"", bookAbstract:"", bookDimension:"", isbn:"" });
        setDispMsg('');
        setImageFile('');
    }

    const handleSearch=async(event)=>{
        event.preventDefault();
        try{
            setLoaded(false);
            setDispMsg('');
            const { idToken } = (await fetchAuthSession()).tokens ?? {};
            const clientID="VJCollege";
            await axios.get(`${config.api.invokeURL}/vjlibrary/search/books?fetchKey=${searchValue}&fetchOption=${searchTopic}&clientID=${clientID}`, {
            headers: {
              Authorization: idToken,
             'x-api-key':config.api.key
            }
          }).then(async(response) => {
           //console.log("response : ", response.data);
           setDispMsg(response.data.length+' - Books Found');
              setSearchResult(response.data);
            enqueueSnackbar(response.data.length+' - Books Found',{variant:'success'}); 
            setLoaded(true);
        }).catch((error)=>{
          enqueueSnackbar("Error Occured - " + error.message,{variant:'warning'}); 
        setLoaded(true);
      })
        }catch(error){
            //console.log("error:", error);
            enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});

        }
    }

    const handleClose = () => setShow(false);

    const handleShow = (event, mode, item) => {
      event.preventDefault();
      setFormType(mode);
      if(mode==="View"){
        setViewOnly(true);
        setBookDetails(item);
        setModalTitle('View Book - '+item.accessionNo);  
        const tImageName=item.accessionNo+".jpeg";
        setProfileImage(`${config.s3.bookPoster}${tImageName}`);
        //setProfileImage('https://s3.ap-south-1.amazonaws.com/images.vidyajyoticollege.in/library/poster/158546.jpeg');   
      }else if(mode==="Edit"){
        setViewOnly(false);
        setBookDetails(item);
        setModalTitle('Edit Book - '+item.accessionNo);     
      }else if(mode==="New"){
        //setBookDetails({...bookDetails, autoGenerate:true});
        handleReset();
        setViewOnly(false);
        setModalTitle('Add a Book');     
      }else if(mode==="Clone"){
        setViewOnly(false);
        item.accessionNo="";
        setBookDetails(item);
        setModalTitle('Clone Book - '+item.accessionNo);     
      }
      setShow(true);
    }

    const handleGoBack=(event)=>{
      event.preventDefault();
      setFormType("New");
      setModalTitle('Preview - Book Details');
      setViewOnly(false);
    }

  return (
    <>
    <Helmet>
      <title>Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies</title>
      <meta name="description" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" />
      <meta name="keywords" content="Library - Vidyajyoti College of Theology - Delhi || Institute of Religious Studies" /> 
    </Helmet>

    <Modal show={show} onHide={handleClose} size='xl' >
        <Modal.Header closeButton>
        <Modal.Title>{modalTitle} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        {formType==="Preview" ? <BooksPreview bookDetails={bookDetails} setBookDetails={setBookDetails} viewOnly={viewOnly} handleClose={handleClose} dispMsg={dispMsg}/>
        :<BooksForm bookDetails={bookDetails} setBookDetails={setBookDetails} viewOnly={viewOnly} handleClose={handleClose} formType={formType} dispMsg={dispMsg} 
        setNewFileName={setNewFileName} setImageFile={setImageFile} profileImage={profileImage}/>}
        <div className="mt-3 text-center">
            {formType==="New" || formType==="Clone" ?<button variant="primary" className='btn-box' type="submit" onClick={(event)=>{handlePreviewView(event)}}> Preview</button>
            :formType==="Preview"?<button variant="primary" className='btn-box' type="submit" onClick={(event)=>{handleNewSubmit(event)}}> Save</button>
            :formType==="Edit"?<button variant="primary" className='btn-box' type="submit" onClick={(event)=>{handleEditSubmit(event)}}> Update</button>:null}
            {"      "}
            <button variant="primary" className='btn-box' type="submit" onClick={(event)=>{handleClose()}}>Close</button>
            {"      "}
            {formType==="Preview" && <button variant="primary" className='btn-box' type="submit" onClick={(event)=>{handleGoBack(event)}}> Go Back</button>}
        </div>   
        </Modal.Body>
    </Modal>

    <div className='container' style={{paddingTop:"50px"}}>
      <header className="section p-4">
      {!loaded && <Preloader show={loaded ? false : true} />}
      <button type="submit" id="form-submit" className="btn-box" onClick={event=>handleShow(event, "New")}><i className="fa fa-plus" aria-hidden="true"></i> New</button>
      <section className="search_section">
      <div className="container">
        <div className="heading_container ">
        <h2>
            Manage your Book's
        </h2>
        </div>
          <form action="#" className='card p-2'>
          <div className="row">
          <div className="col-md-3">
            <select name="searchOption" id="bookSearch"
            onChange={event=>setSearchTopic(event.target.value)}>
                  <option value="AccessionNo">Book ID</option>
                  <option value="BookTitle">Book Title</option>
                  <option value="BookAuthor">Author</option>
            </select>            
          </div>
            <div className="col-md-7 ">
                <input type="text" placeholder="" onChange={event=>setSearchValue(event.target.value)}/>
            </div>
            <div className="col-md-2 text-center">
              <button className='btn-box' onClick={event=>handleSearch(event)}>
                Search
              </button>
            </div>
            </div>
          </form>
    </div>
    </section>
    <div>
    {searchResult.length>0?<Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Accession No</th>
          <th>Book Title</th>
          <th>Author</th>
          <th>Classification No</th>
          <th>Status</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
      {searchResult.map((item,index)=>{return(
        <tr>
          <td>{index+1}</td>
          <td>{item.accessionNo}</td>
          <td>{item.bookTitle}</td>
          <td>{item.bookAuthor}</td>
          <td>{item.bookClassificationNo}{" "}{item.authorMark}</td>
          <td>{item.bookStatus}</td>
          <td>
          <Dropdown as={ButtonGroup}>
            <Dropdown.Toggle as={Button} split variant="link" className="text-dark m-0 p-0">
              <span className="icon icon-sm">
                <i className="fa fa-ellipsis-h icon-dark" aria-hidden="true"></i>
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/*<Dropdown.Item onClick={(event)=>{handleEmailUpdateView(event,item)}} >
                <FontAwesomeIcon icon={faExchange} className="me-2" /> Change Email Address
              </Dropdown.Item>*/}
              <Dropdown.Item onClick={(event)=>{handleShow(event,"View",item)}} >
              <i className="fa fa-eye me-2" aria-hidden="true"></i> View Book
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleShow(event,"Edit",item)}} >
              <i class="fa fa-pencil me-2" aria-hidden="true"></i> Edit Book
              </Dropdown.Item>
              <Dropdown.Item onClick={(event)=>{handleShow(event,"Clone",item)}} >
              <i class="fa fa-clone me-2" aria-hidden="true"></i> Clone Book
              </Dropdown.Item>

            </Dropdown.Menu>
          </Dropdown>
          </td>
        </tr>
      )})}
      </tbody>
      </Table>:null}
    </div>
    </header>
    </div>
    </>
  )
}
