import React, {useState} from 'react';
import { useNavigate } from "react-router-dom";
import { fetchAuthSession } from 'aws-amplify/auth';
import { useSnackbar } from 'notistack';

const ProductContext = React.createContext();;
const config = require('./config.json');

const ProductProvider = (props) => {
const navigate = useNavigate();
const { enqueueSnackbar } = useSnackbar();
const [loggedUser, setLoggedUser] = useState({username: 'Guest', email: '', phoneno: '', familyName:'', profile:'', name:'', isAuthenticated:false, isAdmin:false});
const [displayBooks, setDisplayBooks] = useState([]);
const [myProfileImage, setMyProfileImage] = useState('defaultAvatar.jpg');
const [loginValidated, setLoginValidated] = useState(false);

const validateLogin = async() =>{
    try{
        await fetchAuthSession()
        .then(async(user)=>{
        console.log("User : ", user);
        var tadmin=false;
        const {email, profile, name, nickname,family_name, birthdate, phone_number, gender, website, picture }= user.tokens.idToken.payload;
        if(profile==="Admin"||profile==="Super Admin"||profile==="Librarian"){
            tadmin=true;
        }
        if(profile==="Admin"||profile==="Student"||profile==="Super Admin"||profile==="Librarian"||profile==="Super Student"||profile==="Faculty"){
            await setLoggedUser({...loggedUser,
            name:name,
            email:email,
            profile:profile,
            DOB:birthdate,
            website:website,
            nickname:nickname,
            gender:gender,
            family_name:family_name,
            phone_number:phone_number,
            isAuthenticated:true,
            picture:picture,
            isAdmin:tadmin
        });
        //setSidebarStatus("Orginal");
        if(profile==="Admin"||profile==="Super Admin"||profile==="Librarian"||profile==="Faculty"){
            const tImageName=nickname+".jpeg";
            setMyProfileImage(`${config.s3.imageURL}${"avatars/admins/"}${tImageName}`);
        }else if(profile==="Student"||profile==="Super Student"){
            const tImageName=picture+".jpeg";
            setMyProfileImage(`${config.s3.ProfileImageURL}${"avatar/"}${picture}${"/"}${tImageName}`);
        }
        navigate("/");
        //console.log("User : ", loggedUser);
        // fetchOpenItems();
        }else{
            enqueueSnackbar("You are not authorized to access this portal",{variant : 'warning'});
            navigate("/login");
        }        
        })
        .catch((error) =>{
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        setLoggedUser({...loggedUser,
            name:'',
            email:'',
            DOB:'',
            profile:'',
            website:'',
            nickname:'',
            gender:'',
            family_name:'',
            phone_number:'',
            picture:'',
            isAuthenticated:false,
            isAdmin:false
        }); 
        navigate("/login");
    })     
    }catch(error){
        enqueueSnackbar("Error Occured - " + error.message,{variant : 'warning'});
        navigate("/login");
    }
}

const generateDate=()=>{
    const current_datetime = new Date();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const generateReturnDate=(tempDay)=>{
    const current_datetime = new Date();
    //var result = new Date(date);
    current_datetime.setDate(current_datetime.getDate() + tempDay);
  
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString();
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate()).toString();
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    const accountNo = tempUserId.concat(date_year,"-",month_value,"-", date_value);
    //setUserId(accountNo);
    //setMemberInfo({...memberInfo, userId:accountNo});
    return accountNo;
    //console.log("acc : ", accountNo);
  
}

const generateAccessionNo=()=>{
    const current_datetime = new Date();
    //const fName=profileDetails.firstName.trim().replace(/\s/g, "");
    //const tfName= fName.substring(0,2).toUpperCase();
    //const lName=profileDetails.lastName.trim().replace(/\s/g, "");
    //const tlName= lName.substring(0,2).toUpperCase();
    var tempUserId="";
    const date_year = (current_datetime.getFullYear()).toString().substring(2,4);
    var date_month = (current_datetime.getMonth() + 1).toString();
    var month_value="";
    date_month.length===1? (month_value=month_value.concat(0,date_month)):(month_value=date_month);
    var date_date = (current_datetime.getDate().toString());
    var date_value="";
    date_date.length===1?(date_value=date_value.toString().concat(0,date_date)):(date_value=date_date);
    var date_hours = (current_datetime.getHours().toString());
    var hours_value="";
    date_hours.length===1?(hours_value=hours_value.toString().concat(0,date_hours)):(hours_value=date_hours);
    var date_minutes = (current_datetime.getMinutes().toString());
    var minutes_value="";
    date_minutes.length===1 ?(minutes_value=minutes_value.toString().concat(0,date_minutes)):(minutes_value=date_minutes);
    var date_seconds = (current_datetime.getSeconds().toString());
    var seconds_value="";
    date_seconds.length===1 ?(seconds_value=seconds_value.toString().concat(0,date_seconds)):(seconds_value=seconds_value);
    
    var date_milliseconds = (current_datetime.getMilliseconds().toString());
    var milliseconds_value="";
    date_milliseconds.length===1 ?(milliseconds_value=milliseconds_value.toString().concat(0,date_milliseconds)):(milliseconds_value=milliseconds_value);

    const accountNo = tempUserId.concat(date_year,month_value, date_value, hours_value, minutes_value,seconds_value,milliseconds_value);
    return accountNo;
}


    return (
        <ProductContext.Provider value={{
            loggedUser,
            setLoggedUser,
            displayBooks,
            setDisplayBooks,
            validateLogin,
            myProfileImage,
            generateDate,
            generateReturnDate,
            generateAccessionNo,
            loginValidated, 
            setLoginValidated
        }}>
        {props.children}
        </ProductContext.Provider>
    )
}

export {ProductProvider, ProductContext};
