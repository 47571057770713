import React,{useEffect, useState} from 'react';
import { Table } from 'react-bootstrap';

export default function SearchTableView(props) {
    const {searchResult, handleShow, setSearchResult} = props;
    const [sorting, setSorting]=useState({field:"BookTitle",order:"asc"});

    useEffect(()=>{
      const tempOrginalValue=searchResult;
      //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        var sortedOptionArray = tempOrginalValue.sort((a, b) => {
        if (a.bookTitle < b.bookTitle) {
          return 1;
        }
        if (a.bookTitle > b.bookTitle) {
          return -1;
        }
        return 0;
      });
      setSorting({field:"BookTitle",order:"desc"});
      setSearchResult(sortedOptionArray);
    },[])

    const handleSortKey =async(event, value, order)=>{
     // event.preventDefault();
      if(value==="AccessionNo" && order==="asc"){
        const tempOrginalValue=searchResult;
        //setBookResult([]);
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        var sortedOptionArray = tempOrginalValue.sort((a, b) => {return a.accessionNo - b.accessionNo;});
        await setSearchResult(sortedOptionArray);
        setSorting({field:"AccessionNo",order:"asc"});
      }else if(value==="AccessionNo" && order==="desc"){
        const tempOrginalValue=searchResult;
        //setBookResult([]);
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        sortedOptionArray = tempOrginalValue.sort((a, b) => {return b.accessionNo - a.accessionNo});
        await setSearchResult(sortedOptionArray);
        setSorting({field:"AccessionNo",order:"desc"});
      }else if(value==="BookTitle" && order==="asc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        sortedOptionArray = tempOrginalValue.sort((a, b) => {
          if (a.bookTitle < b.bookTitle) {
            return 1;
          }
          if (a.bookTitle > b.bookTitle) {
            return -1;
          }
          return 0;
        });
        setSorting({field:"BookTitle",order:"asc"});
        await setSearchResult(sortedOptionArray);
      }else if(value==="BookTitle" && order==="desc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
          sortedOptionArray = tempOrginalValue.sort((a, b) => {
            if (a.bookTitle < b.bookTitle) {
              return -1;
            }
            if (a.bookTitle > b.bookTitle) {
              return 1;
            }
            return 0;
          }); 
        setSorting({field:"BookTitle",order:"desc"});
        await setSearchResult(sortedOptionArray);
      }else if(value==="BookAuthor" && order==="asc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        sortedOptionArray = tempOrginalValue.sort((a, b) => {
          if (a.bookAuthor < b.bookAuthor) {
            return 1;
          }
          if (a.bookAuthor > b.bookAuthor) {
            return -1;
          }
          return 0;
        });
        setSorting({field:"BookAuthor",order:"asc"});
        await setSearchResult(sortedOptionArray);
      }else if(value==="BookAuthor" && order==="desc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        sortedOptionArray = tempOrginalValue.sort((a, b) => {
            if (a.bookAuthor < b.bookAuthor) {
              return -1;
            }
            if (a.bookAuthor > b.bookAuthor) {
              return 1;
            }
            return 0;
          }); 
        setSorting({field:"BookAuthor",order:"desc"});
        await setSearchResult(sortedOptionArray);
      }else if(value==="IssuedTo" && order==="asc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
        sortedOptionArray = tempOrginalValue.sort((a, b) => {
          if (a.bookedfor < b.bookedfor) {
            return 1;
          }
          if (a.bookedfor > b.bookedfor) {
            return -1;
          }
          return 0;
        });
        setSorting({field:"IssuedTo",order:"asc"});
        await setSearchResult(sortedOptionArray);
      }else if(value==="IssuedTo" && order==="desc"){
        const tempOrginalValue=searchResult;
        //const tempFilterValue=tempOrginalValue.sort((a,b)=>)
          sortedOptionArray = tempOrginalValue.sort((a, b) => {
            if (a.bookedfor < b.bookedfor) {
              return -1;
            }
            if (a.bookedfor > b.bookedfor) {
              return 1;
            }
            return 0;
          }); 
        setSorting({field:"IssuedTo",order:"desc"});
        await setSearchResult(sortedOptionArray);
      }
    }

  return (
    <div className='p-4'>
        {searchResult.length>0 ? <Table striped bordered hover responsive>
      <thead>
        <tr>
          <th>#</th>
          <th>Accession No  {sorting.field==="AccessionNo" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "AccessionNo","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "AccessionNo", "asc")}></i>}</th>
          <th>Book Title {sorting.field==="BookTitle" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "BookTitle", "asc")}></i>}</th>
          <th>Author {sorting.field==="BookAuthor" && sorting.order==="asc" ? <i class="fa fa-arrow-down" aria-hidden="true" onClick={event=>handleSortKey(event, "BookAuthor","desc")}></i>:<i class="fa fa-arrow-up" aria-hidden="true" onClick={event=>handleSortKey(event, "BookAuthor", "asc")}></i>}</th>
          <th>Call No</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
      {searchResult.map((item,index)=>{return(
        <tr key={index} onClick={event=>handleShow(event, item)}>
          <td>{index+1} </td>
          <td>{item.accessionNo} </td>
          <td>{item.bookTitle}</td>
          <td>{item.bookAuthor}</td>
          <td>{item.bookClassificationNo}{" "}{item.authorMark}</td>
          <td>{item.bookStatus}</td>
        </tr>
      )})}
      </tbody>
      </Table>:null}
    </div>
  )
}
